import React from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';

const SMF = () => {
    return (
      <section className="feedback">
        <div className="container">
          <h2 className="dark_header limit-header"><span>Пришло время вернуть </span> свою целостность!</h2>
          <p className='text-landing'>Филигранное мастерство и ясное видение ведущей семинара, помноженные на глубокое уважение к чувствам каждого творят подлинные чудеса преображения! </p>
          <p className='text-landing'>А поддерживающее пространство участников семинара создаёт чувство любящей семьи, где каждый вносит неоценимый вклад в исцеление души!</p>
        </div>
      </section>
    );
};

export default observer(SMF);