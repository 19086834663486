import React from 'react';
import {observer} from "mobx-react-lite";

const SMC = () => {
  return (
    <section className="courses">
      <div className="container">
        <h2 className="dark_header"><span>«Очевидное невероятно» </span> для вас, если:</h2>
        <div className="courses-block">
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">В вашей жизни есть такие сферы, где вы чувствуете себя в тупике или ловушке</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Вы много лет мучаетесь какой-то внутренней проблемой, но не решаетесь ее озвучить даже перед собой.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">У вас есть незавершённые ситуации или отношения, где любой выход кажется неприемлемым</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Вы уже «прорабатывали» проблему различными способами, но она вновь и вновь воссоздается в жизни.</div>
              </div>
            </div>
          </div>


          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Вы запрашиваете новые возможности и качество своей жизни <br /><br />Существует какая-то тайна, которая пожирает ваш внутренний ресурс.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Ваша жизнь превратилась в череду пресных монотонных будней.<br /><br />Вы чувствуете, что какая-то живая часть вашей души застряла в прошлой травме.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Если вы ощущаете, что ответы на Ваши вопросы находятся на уровне коллективного<br /><br />Бессознательного или готовы принять Ченнелинг от Духовной Иерархии.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Если вы сердцем слышите призыв сил Света и готовы служить человечеству.</div>
              </div>
            </div>
          </div>

          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Ваши отношения с детьми, родителями, мужем или женой оставляют желать лучшего.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Финансовые проблемы преследуют вас, или деньги утекают, как песок сквозь пальцы.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Что-то удерживает вас от желанной самореализации.</div>
              </div>
            </div>
          </div>
        </div>    
      </div>
    </section>
    );
};

export default observer(SMC);