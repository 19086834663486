import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import axios from 'axios';
import errorNotify, { successNotify } from '../services/notify';
import { API_URL } from '../http';

const SLC = () => {
  const [practics, setPractics] = useState(true);
  const [settings, setSettings] = useState([]);
  const [modalVideo, setModalVideo] = useState(true);
  const [buyModal, setBuyModal] = useState(true);
  const [checked, setChecked] = useState(1);
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');      
  const [model, setModel] = useState(0);
  const [typePayment, setTypePayment] = useState(1);
  const fullPayment = 3200000;
  const fullPaymentDecorative = '32 000';
  const fullPaymentDeposit = 2200000;
  const fullPaymentDepositDecorative = '22 000';
  const prePayment = 1000000;
  const prePaymentDecorative = '10 000';
  const prePaymentDeposit = 500000;
  const prePaymentDepositDecorative = '5 000';
  const depositDecorative = '10 000';
  const deposit = 1000000;
  const altDepositDecorative = '5 000';
  const altDeposit = 500000;
  const courseName = '«Очевидное-невероятно»';
  function ShowModalBuy() {
    setBuyModal(!buyModal);
    if(buyModal) document.getElementById("modalPurchase").style.display = "block";
    else document.getElementById("modalPurchase").style.display = "none";
  }

  function initPayment() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    if(model === 0) {
      return errorNotify('Выберите тип обучения!');
    }
    let itemAmount;
    let itemName;
    let purchaseOption;
    if(typePayment === 1) if (model === '1') {
      itemAmount = Number(fullPayment);
      itemName = courseName + ' (Индивидуальный процесс)';
      purchaseOption = 2;
    }
    if(typePayment === 1) if (model === '2') {
      itemAmount = Number(prePayment);
      itemName = courseName + '(Группа общей фасилитации)';
      purchaseOption = 2;
    }
    if(typePayment === 2) if (model === '1') {
      itemName = courseName + ' (Индивидуальный процесс) - депозит';
      itemAmount = Number(deposit);
      purchaseOption = 1;
    }
    if(typePayment === 2) if (model === '2') {
      itemName = courseName + '(Группа общей фасилитации) - депозит';
      itemAmount = Number(altDeposit);
      purchaseOption = 1;
    }
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }
    return (
      <>
          <div id="modalPurchase" className="modal-buy">
            <div className="modal-content">
              <span onClick={() => ShowModalBuy()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Очевидное-невероятно»</h3>
              <hr className="modal-feedback-hr" />
              <p className="modal-title">Формат участия</p>
              <div className="select-wrapper_buy">
                <select defaultValue={model} onChange={(e) => setModel(e.target.value)} className="form-select_buy">
                  <option value={0}>Выберите формат участия </option>
                  <option value={1}>Индивидуальный процесс </option>
                  <option value={2}>Группа общей фасилитации </option>
                </select>
              </div>
              <p className="modal-title">Модель оплаты</p>
              { typePayment === 1 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment">Внести депозит</button>
              </div>
              : typePayment === 2 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment_alt">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment_alt">Внести депозит</button>
              </div>
              : null
              }
              { typePayment === 2 ?
                model === 0 ?
                <div className='disclamer-payment-box'>
                  <p>Невозможно рассчитать стоимость курса, необходимо выбрать модель обучения</p>
                </div>
                :
                <div className='disclamer-payment-box'>
                  <p>Вы вносите депозит в размере {model === '1' ? depositDecorative : altDepositDecorative} руб; остаток – {model === '1' ? fullPaymentDepositDecorative : model === '2' ? altDepositDecorative : null}. Оставшуюся сумму необходимо внести за 2 дня до начала курса</p>
                </div>
                : null
              }
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Семинар-практикум:</p>
                  <p className='card_buy-confirm-text'>«Очевидное-невероятно»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Формат:</p>
                  <p className='card_buy-confirm-text'>{model === '0' ? 'Не выбран формат' : model === '1' ? 'Индивидуальный процесс' : model === '2' ? 'Группа общей фасилитации' : 'Не выбран формат'}</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>02.03.2024</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>07.03.2024</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{typePayment === 1 && model === '1' ? fullPaymentDecorative + ' ₽' : typePayment === 1 && model === '2' ? prePaymentDecorative + ' ₽' : typePayment === 2 && model === '1' ? depositDecorative + ' ₽' : typePayment === 2 && model === '2' ? altDepositDecorative + ' ₽' : 'Неизвестно'}</p>
                </div>
              </div>
              <button onClick={() => initPayment()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
      <section className="feedback">
        <div className="container">
          <h2 className="dark_header limit-header">Варианты <span> участия и стоимость</span></h2>
          <div className='costs-variants'>
            <div className='cost_variants-items'>
              <h2 className="dark_header limit-header header-cost"><span>32 000</span> руб.</h2>
              <p className='text-landing'>Индивидуальный процесс</p>
            </div>
            <div className='cost_variants-items'>
              <h2 className="dark_header limit-header header-cost"><span>10 000</span> руб.</h2>
              <p className='text-landing'>Группа общей фасилитации</p>
            </div>
          </div>
          <div className="group_circle-btn btn-cost">
            <a onClick={() => ShowModalBuy()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a href="https://elenaera.ru/pages/3" target='_blank'>
              <button className="circle-btn_dashed">
                Орг. информация
              </button>
            </a>
          </div>
        </div>
      </section>
      </>
    );
};

export default observer(SLC);